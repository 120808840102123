<template>
    <div class="main_container">
        <NavBar />
        <div class="explore_content">
            <div class="explore_head">
                <div class="explore_head-info">
                    <div class="explore_title">
                        Done for you lead generation
                    </div>
                    <p class="explore_description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has..</p>
                    <b-button class="explore_btn">Start Getting Leads</b-button>
                </div>
                <div class="explore_head-cards">
                    <div class="card_item"></div>
                    <div class="card_item"></div>
                    <div class="card_item"></div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
    import NavBar from "../components/NavBar";
    import Footer from "../components/Footer";
    export default {
        name: "Explore",
        components: {
            Footer,
            NavBar,
        }
    }
</script>

<style scoped lang="scss">
.main {
    &_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
    .explore {
        &_content {
            width: 100%;
            padding: 100px 0 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 1640px;
            margin: 0 auto;
        }
        &_head {
            padding: 20px 0;
        }
    }
</style>